<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-2 text-center pm-0" v-for="category in categories" :key="category.type">
        <router-link :to="{ name: `${category.prefix}.auctions.${category.type}` }" class="btn nr-block text-sm"
          :class="{ 'btn-info': type === category.type }">
          <span>{{ category.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    type() {
      // Accessed by ref
      let type = this.$route.name
      type = type.split('.')
      return type[type.length - 1]
    },
    categories() {
      const route = this.$route.name
      if (this.$store.getters.isClient) {
        return [
          { prefix: 'client', type: 'following', text: 'اتابعها' },
          { prefix: 'client', type: 'won', text: 'فزت بها' },
          { prefix: 'client', type: 'user-unpaid', text: 'بحاجة الى تسديد' },
        ];
      }

      let categories = []
      if (route.startsWith('admin')) {
        if (this.$store.getters.superAdmin) {
          // Super admin
          categories = [
            { prefix: 'admin', type: 'all', text: 'جميع المزادات' },
            { prefix: 'admin', type: 'current', text: 'الحالية' },
            { prefix: 'admin', type: 'stumbled', text: 'المتعثرة' },
            { prefix: 'admin', type: 'unpaid', text: 'بحاجة الى تسديد' },
            { prefix: 'admin', type: 'need-invoice', text: 'إصدار فواتير' },
            { prefix: 'admin', type: 'need-invoice-checking', text: 'تدقيق فواتير' },
            { prefix: 'admin', type: 'sold-externally', text: 'المسحوبة' },
            { prefix: 'admin', type: 'not-received', text: 'منتهية (غير مستلمة)' },
            { prefix: 'admin', type: 'received', text: 'منتهية (مستلمة)' },
            { prefix: 'admin', type: 'unpaid-floor', text: 'أرضيات غير مدفوعة' },
            { prefix: 'admin', type: 'deleted', text: 'محذوفة' },
          ]
        } else {
          // Assistant
          categories = [
            { prefix: 'admin', type: 'current', text: 'الحالية' },
            { prefix: 'admin', type: 'stumbled', text: 'المتعثرة' },
            { prefix: 'admin', type: 'unpaid', text: 'بحاجة الى تسديد' },
            { prefix: 'admin', type: 'unpaid-floor', text: 'أرضيات غير مدفوعة' },
            { prefix: 'admin', type: 'not-received', text: 'منتهية (غير مستلمة)' },
            { prefix: 'admin', type: 'received', text: 'منتهية (مستلمة)' },
          ]
        }
      } else {
        categories = [
          { prefix: 'user', type: 'user-current', text: 'الاعلى بها حالياً' },
          { prefix: 'user', type: 'following', text: 'يتابعها' },
          { prefix: 'user', type: 'won', text: 'فاز بها' },
          { prefix: 'user', type: 'user-unpaid', text: 'بحاجة الى تسديد' },
          { prefix: 'user', type: 'lost', text: 'خسر فيها' },
          { prefix: 'user', type: 'user-stumbled', text: 'متعثرة' },
          { prefix: 'user', type: 'user-unpaid-floor', text: 'ارضيات غير مدفوعة' },
          { prefix: 'user', type: 'has-reassigned', text: 'تم اعادة تعيينها' },
        ]
      }

      return categories;
    }
  }
}
</script>